export const CAMPAIGN_STATUSES = [
  {
    label: 'Start',
    value: 'Start',
  },
  {
    label: 'Consent Pending',
    value: 'ConsentPending',
  },
  {
    label: 'Text Ongoing',
    value: 'TextOngoing',
  },
  {
    label: 'Ready for Call',
    value: 'ReadyForCall',
  },
  {
    label: 'Other Call',
    value: 'OtherCall',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
];

export const REFERRAL_STATUSES = [
  { label: 'Independent Provider', value: 'HasProvider' },
  { label: 'No Action', value: 'NoAction' },
  { label: 'No Response', value: 'NoResponse' },
  { label: 'Booked - Phone', value: 'BookByPhone' },
  { label: 'Booked - SMS', value: 'BookBySMS' },
  { label: 'Consent Refused', value: 'ConsentRefused' },
  { label: 'Consent Pending', value: 'ConsentPending' },
  { label: 'Text Ongoing', value: 'SMSOngoing' },
  { label: 'Ready for Call', value: 'ReadyForCall' },
  { label: 'Other Call', value: 'OtherCall' },
  { label: 'No Active Number', value: 'NoActiveNumber' },
  { label: 'Has Existing Visit', value: 'HasExistingAppointment' },
  { label: 'Error', value: 'Error' },
  { label: 'Manual Override', value: 'ManualOverride' },
  { label: 'Other', value: 'NotInScope' },
];
