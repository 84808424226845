import './style.scss';

import Button from '@mui/material/Button';
import React from 'react';

import Loader from '../Loader/Loader';

export default function LoadingButton({ classNames = '', disabled, icon, id, isLoading, label, onClick, variant }) {
  const handleClick = (event) => {
    if (!isLoading) {
      onClick(event);
    }
  };

  return (
    <Button
      className={`loading-button ${classNames}`}
      disabled={disabled || isLoading}
      id={id}
      onClick={handleClick}
      variant={variant}
    >
      {isLoading ? <Loader size={25} /> : icon}
      {label}
    </Button>
  );
}
