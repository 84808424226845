import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import MultiSelect from '../../components/MultiSelect/component';
import SingleSelect from '../../components/SingleSelect/component';
import HiroApi from '../../HiroApi';
import {
  resetAuditHistoryFilters,
  selectAuditAction,
  selectAuditCategory,
  selectAuditEndDate,
  selectAuditStartDate,
  selectAuditUsers,
  setAction,
  setAuditUsers,
  setCategory,
} from '../../state/auditHistorySlice';
import { selectAllUsers, selectCurrentFacility, setUsers } from '../../state/systemSlice';
import { AUDIT_ACTIONS, AUDIT_CATEGORIES } from '../../utils/constants/audit-history';
import DateFilter from './DateFilter';

export default function AuditHistoryFilters({ getAuditHistory, loading }) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const facility = useSelector(selectCurrentFacility);
  const action = useSelector(selectAuditAction);
  const category = useSelector(selectAuditCategory);
  const endDate = useSelector(selectAuditEndDate);
  const startDate = useSelector(selectAuditStartDate);
  const users = useSelector(selectAllUsers); // [{}, ...]
  const selectedUsers = useSelector(selectAuditUsers); // [id, ...]

  useEffect(() => {
    if (isEmpty(users) && !isEmpty(facility)) {
      getUsers();
    }

    if (!isEmpty(facility)) {
      getAuditHistory();
    }
  }, [action, category, endDate, selectedUsers, startDate]);

  const getUsers = async () => {
    try {
      const token = await getAccessTokenSilently();
      const users = await HiroApi.getUsers(token);

      dispatch(setUsers(users));
    } catch (error) {
      console.log(error);
    }
  };

  const refresh = () => {
    getAuditHistory();
  };

  const resetFilters = () => {
    dispatch(resetAuditHistoryFilters());
  };

  const handleActionChange = (event) => {
    const selectedAction = event.target.value;

    dispatch(setAction(selectedAction));
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;

    dispatch(setCategory(selectedCategory));
  };

  return (
    <div className="filters">
      <MultiSelect
        className="users"
        label="Users"
        options={users}
        value={selectedUsers}
        labelKey="displayName"
        valueKey="id"
        setSelected={setAuditUsers}
      />
      <SingleSelect
        className="category"
        label="Category"
        onChange={handleCategoryChange}
        options={AUDIT_CATEGORIES}
        value={category}
      />
      <SingleSelect
        className="action"
        label="Action"
        onChange={handleActionChange}
        options={AUDIT_ACTIONS}
        value={action}
      />
      <DateFilter />

      <Button className="reset" onClick={resetFilters}>
        Reset
      </Button>

      {loading ? (
        <Loader size={35} />
      ) : (
        <IconButton onClick={refresh} className="refresh">
          <RefreshIcon />
        </IconButton>
      )}
    </div>
  );
}
