import './style.scss';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuditEndDate, selectAuditStartDate, setEndDate, setStartDate } from '../../state/auditHistorySlice';

export default function DateFilter() {
  const dispatch = useDispatch();
  const startDate = useSelector(selectAuditStartDate);
  const endDate = useSelector(selectAuditEndDate);

  return (
    <div className="date-filter">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={dayjs(startDate)}
          onChange={(date) => {
            dispatch(setStartDate(date.format('MM/DD/YYYY')));
          }}
        />
        <DatePicker
          label="End Date"
          value={dayjs(endDate)}
          onChange={(date) => {
            dispatch(setEndDate(date.format('MM/DD/YYYY')));
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
