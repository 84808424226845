import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Device } from '@twilio/voice-sdk';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import HiroApi from '../../HiroApi';
import { selectCurrentFacility } from '../../state/systemSlice';
import {
  selectIsOpen,
  selectPhoneNumber,
  selectStatus,
  setIsOpen,
  setPhoneNumber,
  setStatus,
} from '../../state/twilioSlice';
import { selectUserEmail } from '../../state/userSlice';
import { CALL_EVENT, DEVICE_STATUS } from '../../utils/constants/twilio';
import { phoneFormat } from '../../utils/helpers';

export default function Twilio() {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const callRef = useRef(null);
  const deviceRef = useRef(null);
  const initializedRef = useRef(false);
  const facility = useSelector(selectCurrentFacility);
  const isOpen = useSelector(selectIsOpen);
  const phoneNumber = useSelector(selectPhoneNumber);
  const status = useSelector(selectStatus);
  const userEmail = useSelector(selectUserEmail);
  const [callSid, setCallSid] = useState('');
  const [campaignId, setCampaignId] = useState('');
  const [foundCampaign, setFoundCampaign] = useState('');
  const [recordingSid, setRecordingSid] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    if (!initializedRef.current) {
      initializeDevice();
      initializedRef.current = true;
    }

    const currentCampaignId = location.pathname.includes('campaign') ? location.pathname.split('/').pop() : '';

    setCampaignId(currentCampaignId);
  }, [location.pathname]);

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      const twilioToken = await HiroApi.getTwilioToken(userEmail, token);

      dispatch(setStatus('Got valid token'));

      return twilioToken.token;
    } catch (error) {
      dispatch(setStatus('Get token failed'));
      console.log(error);
      return null;
    }
  };

  const initializeDevice = async () => {
    if (deviceRef.current) return deviceRef.current;

    try {
      const token = await getToken();
      const twilioDevice = new Device(token);

      twilioDevice.on(DEVICE_STATUS.ERROR.value, (error) => {
        dispatch(setStatus(`Device Error: ${error.message}`));
      });

      twilioDevice.on(DEVICE_STATUS.REGISTERING.value, () => dispatch(setStatus(DEVICE_STATUS.REGISTERING.label)));

      twilioDevice.on(DEVICE_STATUS.REGISTERED.value, () => dispatch(setStatus(DEVICE_STATUS.REGISTERED.label)));

      twilioDevice.on(DEVICE_STATUS.UNREGISTERED.value, () => dispatch(setStatus(DEVICE_STATUS.UNREGISTERED.label)));

      twilioDevice.on(DEVICE_STATUS.DESTROYED.value, () => dispatch(setStatus(DEVICE_STATUS.DESTROYED.label)));

      twilioDevice.on(DEVICE_STATUS.TOKEN_WILL_EXPIRE.value, async () => {
        dispatch(setStatus(DEVICE_STATUS.TOKEN_WILL_EXPIRE.label));

        const newToken = await getToken();
        await twilioDevice.updateToken(newToken);
      });

      twilioDevice.on(DEVICE_STATUS.INCOMING.value, (connection) => {
        const callSid = connection.parameters.CallSid;
        const fromNumber = connection.parameters.From;
        const campaignId = connection.customParameters.get('campaignId');

        callRef.current = connection;
        dispatch(setIsOpen(true));
        setCallSid(callSid);
        setFoundCampaign(campaignId);
        dispatch(setPhoneNumber(fromNumber.replace(/^\+1/, '')));
        dispatch(setStatus(DEVICE_STATUS.INCOMING.label));
        attachCallEventListeners(connection);
      });

      await twilioDevice.register();
      deviceRef.current = twilioDevice;

      return twilioDevice;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const makeCall = async () => {
    let twilioDevice = deviceRef.current;

    if (!twilioDevice) {
      twilioDevice = await initializeDevice();
    }

    try {
      const metadata = {
        facilityId: facility.id,
        campaignId,
        coordinatorId: userEmail,
        to: phoneNumber,
      };

      const outgoingCall = await twilioDevice.connect({ params: { To: phoneNumber, ...metadata } });

      callRef.current = outgoingCall;
      dispatch(setStatus('Calling...'));
      attachCallEventListeners(outgoingCall);
    } catch (error) {
      console.error('Error during call setup:', error);
      dispatch(setStatus('Error: Unable to make the call'));
    }
  };

  const attachCallEventListeners = (connection) => {
    connection.on(CALL_EVENT.ACCEPT.value, () => {
      const callSid = callRef.current?.parameters?.CallSid;

      dispatch(setStatus(CALL_EVENT.ACCEPT.label));
      setIsRecording(true);
      setCallSid(callSid);
    });

    connection.on(CALL_EVENT.DISCONNECT.value, () => {
      dispatch(setStatus(CALL_EVENT.DISCONNECT.label));
      callRef.current = null;
      reset();
    });

    connection.on(CALL_EVENT.ERROR.value, (error) => {
      dispatch(setStatus(`${CALL_EVENT.ERROR.label}: ${error.message}`));
      console.error('Call error:', error);
    });

    connection.on(CALL_EVENT.CANCEL.value, () => {
      dispatch(setStatus('Call canceled'));
      callRef.current = null;
    });

    connection.on(CALL_EVENT.RECONNECTING.value, () => dispatch(setStatus(CALL_EVENT.RECONNECTING.label)));

    connection.on(CALL_EVENT.RECONNECTED.value, () => dispatch(setStatus(CALL_EVENT.RECONNECTED.label)));

    connection.on(CALL_EVENT.RINGING.value, () => dispatch(setStatus(CALL_EVENT.RINGING.label)));

    connection.on(CALL_EVENT.REJECT.value, () => {
      dispatch(setStatus(CALL_EVENT.REJECT.label));
      callRef.current = null;
      reset();
    });
  };

  const acceptCall = () => {
    if (callRef.current) {
      callRef.current.accept();
      dispatch(setStatus(CALL_EVENT.ACCEPT.label));
    }
  };

  const rejectCall = () => {
    if (callRef.current) {
      callRef.current.reject();
      dispatch(setStatus(CALL_EVENT.REJECT.label));
      reset();
    }
  };

  const endCall = () => {
    callRef.current.disconnect();
    callRef.current = null;
    reset();
  };

  const handlePhoneNumberChange = (event) => {
    dispatch(setPhoneNumber(event.target.value));
  };

  const showCallLoading = () => {
    if (status === CALL_EVENT.RINGING.label || status === CALL_EVENT.RECONNECTING.label) {
      return true;
    }

    return false;
  };

  const toggleRecording = async () => {
    try {
      const token = await getAccessTokenSilently();

      if (isRecording) {
        await HiroApi.stopRecording(callSid, recordingSid, token);
      } else {
        const recording = await HiroApi.startRecording(callSid, token);

        setRecordingSid(recording);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsRecording(!isRecording);
    }
  };

  const toggleMute = () => {
    if (callRef) {
      callRef.current.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  };

  const reset = () => {
    callRef.current = null;

    dispatch(setPhoneNumber(''));
    dispatch(setStatus(''));

    setCallSid('');
    setFoundCampaign('');
    setRecordingSid('');
    setIsMuted(false);
    setIsRecording(false);
  };

  return (
    <div className="twilio">
      {isOpen ? (
        <Paper className="twilio" elevation={3}>
          <TextField disabled label="Status" size="small" value={status} variant="standard" />

          <TextField
            label={phoneNumber ? 'Phone Number' : ''}
            onChange={handlePhoneNumberChange}
            placeholder="(123) 456-7890"
            size="small"
            value={phoneFormat(phoneNumber)}
            variant="standard"
          />

          {callRef.current && status === DEVICE_STATUS.INCOMING.label ? (
            <div className="options">
              <Button variant="outlined" onClick={acceptCall}>
                Accept
              </Button>
              <Button variant="outlined" className="reject" onClick={rejectCall}>
                Reject
              </Button>
            </div>
          ) : callRef.current && status === CALL_EVENT.ACCEPT.label ? (
            <Button variant="outlined" onClick={endCall}>
              End Call
            </Button>
          ) : (
            <Button variant="outlined" onClick={makeCall} disabled={isEmpty(phoneNumber) || isEmpty(campaignId)}>
              Call
            </Button>
          )}

          {showCallLoading() ? <LinearProgress className="progress" /> : null}

          <div className="actions">
            <Tooltip title="Mute" placement="bottom" arrow>
              <IconButton onClick={toggleMute}>
                {isMuted ? <MicOffIcon className="red" /> : <MicIcon className="gray" />}
              </IconButton>
            </Tooltip>

            <Tooltip title="Record" placement="bottom" arrow>
              <IconButton onClick={toggleRecording}>
                {isRecording ? <RadioButtonCheckedIcon className="red" /> : <RadioButtonCheckedIcon className="gray" />}
              </IconButton>
            </Tooltip>

            {foundCampaign ? (
              <Tooltip title="Go to campaign" placement="bottom" arrow>
                <IconButton onClick={() => navigate(`/campaigns/${foundCampaign}`)}>
                  <CampaignOutlinedIcon className="green border" />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        </Paper>
      ) : null}

      <Fab
        className={status === DEVICE_STATUS.INCOMING.label ? 'pulse-animation' : ''}
        size="medium"
        onClick={() => dispatch(setIsOpen(!isOpen))}
      >
        <LocalPhoneOutlinedIcon />
      </Fab>
    </div>
  );
}
