import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { find } from 'lodash';
import React from 'react';

import store from '../state/store';
import { CAMPAIGN_STATUSES, REFERRAL_STATUSES } from './constants/campaign-status';
import { CONSENT_STATUS } from './constants/system';

dayjs.extend(utc);
dayjs.extend(timezone);

export const phoneFormat = (input) => {
  let phone = input;

  if (phone) {
    phone = phone.replace(/\D/g, '');
    const size = phone.length;
    if (size > 0) {
      phone = `(${phone}`;
    }
    if (size > 3) {
      phone = `${phone.slice(0, 4)}) ${phone.slice(4, 11)}`;
    }
    if (size > 6) {
      phone = `${phone.slice(0, 9)}-${phone.slice(9)}`;
    }
    return phone;
  }
  return '';
};

export const formatDate = (date, localTimeZone = false) => {
  if (localTimeZone) {
    const state = store.getState();
    const facility = state.system.currentFacility;
    return dayjs.utc(date).tz(facility.timezone).format('M/DD/YYYY');
  }
  return dayjs(date).utc().format('MM/DD/YYYY');
};

export const formatDatetime = (datetime) => {
  const state = store.getState();
  const facility = state.system.currentFacility;

  return dayjs(datetime).tz(facility.timezone).format('M/DD/YYYY h:mm A');
};

export const formatTime = (time) => {
  if (time) {
    const [hour, minute] = time.split(':');

    return dayjs().set('hour', hour).set('minute', minute).format('h:mm A');
  }

  return time;
};

export const getProviderName = (providerId, followupProvider) => {
  if (!providerId) {
    return followupProvider;
  }

  const state = store.getState();
  const providersInStore = state.system.providers;

  return providersInStore.find((provider) => provider.id === providerId)?.displayName;
};

export const getProviderInfo = (providerId) => {
  const state = store.getState();
  const providersInStore = state.system.providers;

  return providersInStore.find((provider) => provider.id === providerId);
};

export const getSpecialtyInfo = (specialtyId) => {
  const state = store.getState();
  const specialtiesInStore = state.system.specialties;

  return specialtiesInStore.find((specialty) => specialty.id === specialtyId);
};

export const getDepartmentInfo = (departmentId) => {
  const state = store.getState();
  const departmentsInStore = state.system.departments;

  return departmentsInStore.find((department) => department.id === departmentId);
};

export const findSpecialtyIdByName = (specialtyName) => {
  const state = store.getState();
  const specialtiesInStore = state.system.specialties;

  return specialtiesInStore.find((specialty) => specialty.name === specialtyName)?.id;
};

export const isValidDateFormat = (date) => {
  // Regular expression to match the format "0/0/0000" or "00/00/0000"
  const regex = /^(\d{1,2})\/(\d{1,2})\/\d{4}$/;

  return regex.test(date);
};

export function addSpacesToCamelCase(string) {
  // Replace every uppercase letter (except the one at the start) with a space followed by that letter
  return string.replace(/([A-Z])/g, ' $1').trim();
}

export const copyToClipboard = async (text) => {
  await navigator.clipboard.writeText(text);
};

export const mapEngagementStatus = (status) => {
  const campaignStatus = find(CAMPAIGN_STATUSES, ['value', status]);

  return campaignStatus?.label;
};

export const mapReferralStatus = (status) => {
  const referralStatus = find(REFERRAL_STATUSES, ['value', status]);

  return referralStatus?.label;
};

export const mapConsentStatus = (consent) => {
  switch (consent) {
    case CONSENT_STATUS.IN:
      return <CheckCircleOutlinedIcon className="green status" />;
    case CONSENT_STATUS.OUT:
      return <CancelOutlinedIcon className="red status" />;
    case CONSENT_STATUS.PENDING:
      return <PendingOutlinedIcon className="gray status" />;
    default:
      return '';
  }
};
