import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userEmail: '',
  userName: '',
  userType: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    resetUserState: () => initialState,
  },
});

export const { setUserEmail, setUserName, setUserType, resetUserState } = userSlice.actions;

export default userSlice.reducer;

export const selectUserEmail = (state) => state.user.userEmail;
export const selectUserName = (state) => state.user.userName;
export const selectUserType = (state) => state.user.userType;
