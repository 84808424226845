export const PATIENT_QUEUES = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Ready For Call',
    value: 'ReadyForCall',
  },
  {
    label: 'Other Call',
    value: 'OtherCall',
  },
  {
    label: 'Text Ongoing',
    value: 'TextOngoing',
  },
  {
    label: 'Needs Action',
    value: 'NeedsAction',
  },
  {
    label: 'Autopilot',
    value: 'Autopilot',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
];
