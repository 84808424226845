import './style.scss';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';

export default function CampaignTypeToggle({ campaignType, onChange, queue }) {
  const handleChange = (event, newType) => {
    onChange(newType);
  };

  const renderSingleIcon = () => {
    if (campaignType === 'SMS') {
      return <SmsOutlinedIcon />;
    }
    return <CallOutlinedIcon />;
  };

  return (
    <div className="toggle">
      {queue === 'unfit' || queue === 'sent' ? (
        renderSingleIcon()
      ) : (
        <ToggleButtonGroup exclusive value={campaignType} onChange={handleChange}>
          <ToggleButton value="SMS">
            <SmsOutlinedIcon />
          </ToggleButton>
          <ToggleButton value="Call">
            <CallOutlinedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </div>
  );
}
