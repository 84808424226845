import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Loader from '../components/Loader/Loader';

export default function ProtectedRoute({ component, ...propsForComponent }) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: { returnTo: location.pathname },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, location]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="loading">
        <Loader />
      </div>
    ),
  });

  return <Component {...propsForComponent} />;
}
