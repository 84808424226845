export const DISQUALIFIERS_ALL_REFERRALS = [
  'Has Active Campaign',
  'Disposition Not Supported',
  'Age Under 2 Years',
  'No Active Number',
  'Opted Out',
  'Duplicate Discharge',
  'Recent Campaign',
];

export const DISQUALIFIERS_SINGLE_REFERRAL = ['Specialty Not Supported', 'Has Existing Visit', 'Has Provider'];
