import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  phoneNumber: '',
  status: '',
};

export const twilioSlice = createSlice({
  name: 'twilioSlice',
  initialState,
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setOpenWithCampaign: (state, action) => {
      state.isOpen = true;
      state.phoneNumber = action.payload.phoneNumber;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setIsOpen, setOpenWithCampaign, setPhoneNumber, setStatus } = twilioSlice.actions;

export const selectIsOpen = (state) => state.twilio.isOpen;
export const selectPhoneNumber = (state) => state.twilio.phoneNumber;
export const selectStatus = (state) => state.twilio.status;

export default twilioSlice.reducer;
