import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import Notification from '../../components/Notification/component';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility, selectUploadFilters, setUploadFilters } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';

export default function UploadList() {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const facility = useSelector(selectCurrentFacility);
  const userType = useSelector(selectUserType);
  const uploadFilters = useSelector(selectUploadFilters);
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploads, setUploads] = useState([]);

  useEffect(() => {
    if (userType !== USER_ROLES.ADMIN && !isEmpty(facility)) {
      navigate('/dashboard');
    }

    if (!isEmpty(facility)) {
      getUploads(uploadFilters.startDate, uploadFilters.endDate);
    }
  }, [uploadFilters]);

  const getUploads = async (startDate, endDate) => {
    try {
      const token = await getAccessTokenSilently();
      const uploads = await HiroApi.getUploads({ startDate, endDate }, token);

      setUploads(uploads);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const openFileDialog = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];

    // Show error if same file uploaded
    if (file && file.name === fileObj.name) {
      setError(true);

      return;
    }

    setFile(fileObj);
    event.target.value = null;
  };

  const uploadFile = async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const formData = new FormData();

      formData.append('file', file, file.name);
      await HiroApi.uploadPatients(formData, token);
      setFile(null);
      await getUploads(uploadFilters.startDate, uploadFilters.endDate);
    } catch (err) {
      setLoading(false);
      setApiErrors(err);
    }
  };

  return (
    <div className="upload">
      <Notification errors={apiErrors} />
      {error ? (
        <Alert
          severity="error"
          onClose={() => {
            setError(false);
          }}
        >
          File already added
        </Alert>
      ) : null}

      <h1>Discharges</h1>

      <div className="upload-header">
        <div className="file-upload">
          <IconButton onClick={openFileDialog}>
            <AddCircleOutlineIcon />
            <input ref={inputRef} type="file" onChange={handleFileChange} />
            <span>Upload File</span>
          </IconButton>

          {file ? (
            <div className="file-info">
              <p>{file.name}</p>
              <Button variant="contained" className="button" onClick={uploadFile}>
                Upload
              </Button>
            </div>
          ) : null}

          {loading ? <CircularProgress /> : null}
        </div>

        <div className="date-filters">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={dayjs(uploadFilters.startDate)}
              onChange={(date) => {
                dispatch(setUploadFilters({ startDate: date.format('MM/DD/YYYY') }));
              }}
            />
            <DatePicker
              label="End Date"
              value={dayjs(uploadFilters.endDate)}
              onChange={(date) => {
                dispatch(setUploadFilters({ endDate: date.format('MM/DD/YYYY') }));
              }}
            />
          </LocalizationProvider>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="upload-content">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Filename</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Ready</TableCell>
                  <TableCell align="center">Pending</TableCell>
                  <TableCell align="center">Sent</TableCell>
                  <TableCell align="center">Not Fit</TableCell>
                  <TableCell align="center">Total Discharges</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploads.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="center">
                      <Link to={`/patients/upload/${row.id}`}>{row.name}</Link>
                    </TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.ready}</TableCell>
                    <TableCell align="center">{row.pending}</TableCell>
                    <TableCell align="center">{row.sent}</TableCell>
                    <TableCell align="center">{row.unfit}</TableCell>
                    <TableCell align="center">{row.ready + row.sent + row.pending + row.unfit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
