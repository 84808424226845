import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar, BarChart, Label, LabelList, XAxis, YAxis } from 'recharts';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility } from '../../state/systemSlice';

export default function Reporting() {
  const { getAccessTokenSilently } = useAuth0();
  const facility = useSelector(selectCurrentFacility);
  const [bookingRates, setBookingRates] = useState([]);

  useEffect(() => {
    if (!isEmpty(facility)) {
      getChart('booking-rates');
    }
  }, []);

  const getChart = async (chartName) => {
    try {
      const token = await getAccessTokenSilently();
      const data = await HiroApi.getAnalytics(chartName, token);

      setBookingRates(data);
    } catch (error) {
      console.log(error);
    }
  };

  const barLabelFormatter = (value) => {
    if (value < 0.1) {
      return null;
    }

    return `${value}%`;
  };

  return (
    <div className="reporting">
      <h1>Reporting</h1>

      <div className="chart">
        <h3>Booking Rates</h3>

        {isEmpty(bookingRates) ? (
          <Loader />
        ) : (
          <BarChart width={600} height={300} data={bookingRates}>
            <XAxis dataKey="week" tickLine={false} tickSize={2}>
              <Label value="Week" offset={0} position="insideBottom" />
            </XAxis>
            <YAxis tickCount={7} tickLine={false} tickSize={2.5} domain={[0, 25]}>
              <Label value="Percentage" offset={130} position="insideBottom" angle={-90} />
            </YAxis>
            <Bar dataKey="rate" fill="#20CE99">
              <LabelList dataKey="rate" position="top" fill="black" formatter={barLabelFormatter} />
            </Bar>
          </BarChart>
        )}
      </div>
    </div>
  );
}
