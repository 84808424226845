import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import IconButton from '@mui/material/IconButton';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import HiroApi from '../../HiroApi';
import Loader from '../Loader/Loader';

export function AudioPlayer({ recording }) {
  const { getAccessTokenSilently } = useAuth0();
  const audioRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('playing', () => setIsPlaying(true));
      audioRef.current.addEventListener('pause', () => setIsPlaying(false));
      audioRef.current.addEventListener('ended', handleEnd);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('playing', () => setIsPlaying(true));
        audioRef.current.removeEventListener('pause', () => setIsPlaying(false));
        audioRef.current.removeEventListener('ended', handleEnd);
      }
    };
  }, []);

  const getRecording = async (recordingSid) => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();

      return await HiroApi.getRecording(recordingSid, token);
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handlePlay = async () => {
    if (!audioRef.current.src) {
      setLoading(true);

      try {
        const stream = await getRecording(recording.sid);

        if (stream) {
          audioRef.current.src = stream;
          await audioRef.current.play();
        }
      } catch (error) {
        console.log('Failed to load audio');
      } finally {
        setLoading(false);
      }
    } else {
      audioRef.current.play();
    }
  };

  const handlePause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const handleEnd = () => {
    setIsPlaying(false);

    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };

  return (
    <div className="audio-player">
      <audio ref={audioRef} onEnded={handleEnd}>
        <track kind="captions" />
      </audio>

      {loading ? (
        <IconButton>
          <Loader size={20} />
        </IconButton>
      ) : isPlaying ? (
        <IconButton onClick={handlePause}>
          <PauseCircleOutlinedIcon className="gray" />
        </IconButton>
      ) : (
        <IconButton onClick={handlePlay}>
          <PlayCircleOutlinedIcon className="green" />
        </IconButton>
      )}
    </div>
  );
}
