import './style.scss';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';

export default function SingleSelect({ className, label, onChange, options, value }) {
  return (
    <FormControl size="small" className={`${className} single-select`}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onChange} input={<OutlinedInput label={label} />}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
