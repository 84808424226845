import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import React from 'react';

import logo from '../../assets/logo.svg';

const appDomain = process.env.COHERENCE_ENVIRONMENT_DOMAIN || process.env.REACT_APP_HOST;

export default function Login() {
  const APP_HOST = appDomain.startsWith('http') ? appDomain : `https://${appDomain}`;
  const { isLoading, loginWithRedirect } = useAuth0();

  const login = () =>
    loginWithRedirect({
      redirectUri: `${APP_HOST}/dashboard`,
      screen_hist: 'login',
    });

  return (
    <div className="login-container">
      {isLoading ? null : (
        <div className="box">
          <img src={logo} alt="Kouper Health" />
          <Button variant="contained" className="login-button" onClick={login}>
            Login
          </Button>
        </div>
      )}
    </div>
  );
}
