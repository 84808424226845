import './style.scss';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch } from 'react-redux';

export default function MultiSelect({ className, label, options, labelKey, valueKey, setSelected, value }) {
  const dispatch = useDispatch();
  const areAllSelected = options.length === value.length;
  const areNoneSelected = value.length === 0;

  const handleChange = (event) => {
    const selected = event.target.value;

    dispatch(setSelected(selected));
  };

  const selectAll = () => {
    const optionValues = options.map((option) => option[valueKey]);

    dispatch(setSelected(optionValues));
  };

  const deselectAll = () => {
    dispatch(setSelected([]));
  };

  return (
    <FormControl size="small" className={`${className} multi-select`}>
      <InputLabel>{label}</InputLabel>
      <Select multiple value={value} onChange={handleChange} input={<OutlinedInput label={label} />}>
        <MenuItem className="multi-select-all" onClick={selectAll} disabled={areAllSelected}>
          Select All
        </MenuItem>
        <MenuItem className="multi-select-all" onClick={deselectAll} disabled={areNoneSelected}>
          Deselect All
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
