export const AUDIT_ACTIONS = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Create',
    value: 'Create',
  },
  {
    label: 'Update',
    value: 'Update',
  },
  {
    label: 'Delete',
    value: 'Delete',
  },
];

export const AUDIT_CATEGORIES = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Appointment',
    value: 'Appointment',
  },
  {
    label: 'Campaign',
    value: 'Campaign',
  },
  {
    label: 'Contact Attempt',
    value: 'ContactAttempt',
  },
  {
    label: 'Referral Status',
    value: 'ReferralStatus',
  },
];
