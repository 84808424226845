import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { copyToClipboard } from '../../utils/helpers';
import { translate } from '../../utils/helpers-api';

export default function RecommendedMessage(props) {
  const { campaignId, messageId, recommendedMessages } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [recommendedMessage, setRecommendedMessage] = useState('');
  const lastRecommendedMessage = recommendedMessage || recommendedMessages[recommendedMessages.length - 1];
  const [feedback, setFeedback] = useState(lastRecommendedMessage?.feedback);
  const [translatedMessage, setTranslatedMessage] = useState({});

  const regenerate = async () => {
    showClick();
    setLoading(false);

    try {
      const token = await getAccessTokenSilently();
      const newRecommendation = await HiroApi.getRecommendedMessage(campaignId, messageId, token);

      setRecommendedMessage(newRecommendation);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const sendFeedback = async (recommendedId, feedback) => {
    showClick();

    try {
      const token = await getAccessTokenSilently();

      await HiroApi.recommendationFeedback(campaignId, recommendedId, feedback, token);
      setFeedback(feedback);
    } catch (error) {
      console.log(error);
    }
  };

  const showClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 100);
  };

  const translateMessage = async (messageId) => {
    setLoading(true);

    const token = await getAccessTokenSilently();
    const translation = await translate({ messageId }, token);

    setTranslatedMessage(translation);
    setLoading(false);
  };

  return (
    <Accordion className="recommended">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Recommended Message</AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className="text">
              {isEmpty(recommendedMessage) ? lastRecommendedMessage?.body : recommendedMessage.body}
            </div>
            {!isEmpty(translatedMessage) ? <div className="text translation">{translatedMessage?.body}</div> : null}
          </div>
        )}

        <div className="actions">
          <Tooltip title="Regenerate">
            <IconButton onClick={regenerate} className={clicked ? 'clicked' : ''}>
              <AutorenewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Good response">
            <IconButton
              onClick={() => sendFeedback(lastRecommendedMessage.id, 'good')}
              className={feedback === 'good' ? 'green' : ''}
            >
              <ThumbUpOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Bad response">
            <IconButton
              onClick={() => sendFeedback(lastRecommendedMessage.id, 'bad')}
              className={feedback === 'bad' ? 'green' : ''}
            >
              <ThumbDownOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton
              onClick={() => {
                showClick();
                copyToClipboard(lastRecommendedMessage.body);
              }}
              className={clicked ? 'clicked' : ''}
            >
              <ContentPasteIcon />
            </IconButton>
          </Tooltip>
          <Chip variant="outlined" label="Translate" onClick={() => translateMessage(lastRecommendedMessage.id)} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
