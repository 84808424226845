import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectAllAuditFilters, selectAuditHistory, setHistory } from '../../state/auditHistorySlice';
import { formatDatetime } from '../../utils/helpers';
import AuditHistoryFilters from './AuditHistoryFilters';

export default function AuditHistory() {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const history = useSelector(selectAuditHistory);
  const allFilters = useSelector(selectAllAuditFilters);

  const getAuditHistory = async () => {
    try {
      const token = await getAccessTokenSilently();
      const history = await HiroApi.getAuditHistory(allFilters, token);

      dispatch(setHistory(history));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const goToRoute = (route, id) => {
    navigate(`/${route}/${id}`);
  };

  return (
    <div className="audit-history">
      <h3>Audit History</h3>

      <AuditHistoryFilters getAuditHistory={getAuditHistory} loading={loading} setLoading={setLoading} />

      {loading ? (
        <Loader />
      ) : (
        <div className="history">
          <TableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">User</TableCell>
                  <TableCell align="center">Date/Time</TableCell>
                  <TableCell align="center">Change</TableCell>
                  <TableCell align="center">Action</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Links</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map(({ id, user, dateTime, change, action, category, patientId, campaignId, ingestionId }) => (
                  <TableRow key={id}>
                    <TableCell align="center">{user}</TableCell>
                    <TableCell align="center">{formatDatetime(dateTime)}</TableCell>
                    <TableCell align="center">{change}</TableCell>
                    <TableCell align="center">{action}</TableCell>
                    <TableCell align="center">{category}</TableCell>
                    <TableCell align="center">
                      {!isEmpty(patientId) ? (
                        <IconButton onClick={() => goToRoute('patients', patientId)}>
                          <AccountCircleOutlinedIcon />
                        </IconButton>
                      ) : null}

                      {!isEmpty(campaignId) ? (
                        <IconButton onClick={() => goToRoute('campaigns', campaignId)}>
                          <CampaignOutlinedIcon />
                        </IconButton>
                      ) : null}

                      {!isEmpty(ingestionId) ? (
                        <IconButton onClick={() => goToRoute('patients/upload', ingestionId)}>
                          <SourceOutlinedIcon />
                        </IconButton>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
